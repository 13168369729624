
body {
    font-family: 'Roboto', sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f0f0f5;
    color: #333;
}

nav ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    background-color: #2c3e50;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

nav ul li {
    margin: 0 20px;
}

nav ul li a {
    color: #ecf0f1;
    text-decoration: none;
    padding: 15px;
    font-weight: bold;
    transition: color 0.3s ease;
}

nav ul li a:hover {
    color: #1abc9c;
}

h1 {
    font-size: 2.5em;
    color: #2c3e50;
    margin-bottom: 20px;
}

p {
    font-size: 1.1em;
    color: #7f8c8d;
    line-height: 1.6em;
    margin-bottom: 20px;
}

div {
    max-width: 800px;
    margin: 50px auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
    nav ul {
        flex-direction: column;
    }

    nav ul li {
        margin: 10px 0;
    }

    div {
        margin: 20px;
    }
}
